import React from 'react';
import AboutUs from '../component/aboutUs/AboutUs';
import { Helmet } from 'react-helmet';
import ComponenetHeader from '../component/common/ComponenetHeader';

const AboutPage = () => {

  return (
    <>
      <Helmet>
        <title>Fast Reefer Repair & Truck Repair – Get Back on the Road!</title>
        <meta name="description" content="Facing a breakdown? Get expert Truck Repair and Reefer Repair with Online Road Services. We also specialize in Trailer Repair—call for quick service!" />

        <meta property="og:title" content="Fast Reefer Repair & Truck Repair – Get Back on the Road!" />
        <meta property="og:description" content="Facing a breakdown? Get expert Truck Repair and Reefer Repair with Online Road Services. We also specialize in Trailer Repair—call for quick service!" />
      </Helmet>
      <ComponenetHeader
        name={'About Us'}
      />
      <AboutUs />
    </>
  );
};

export default AboutPage;