import React from "react";
import Plans from "../component/plan/Plans";
import SearchSection from "../component/search/SearchSection";
import AboutUs from "../component/aboutUs/AboutUs";
import { Helmet } from "react-helmet";

const HomePage =()=>{

    return(
        <div className="main_content">  
            <Helmet>
            <title>Truck Repair, Trailer Repair, Tire Repair, and Towing Services | Online Road Services</title>
            <meta name="description" content="Fast Truck, Trailer, Reefer, Tire Repair, and Towing services to minimize downtime and keep your vehicle on the road safely and efficiently." />
            
            <meta property="og:title" content="Truck Repair, Trailer Repair, Tire Repair, and Towing Services | Online Road Services" />
            <meta property="og:description" content="Fast Truck, Trailer, Reefer, Tire Repair, and Towing services to minimize downtime and keep your vehicle on the road safely and efficiently." />
           </Helmet>
           
            <SearchSection/>
            <AboutUs/>
            <Plans/>
        </div>
    );
};

export default HomePage;