import React from "react";
import ComponenetHeader from "../common/ComponenetHeader";

const TermsAndCondition =()=>{

    return(<>

        <ComponenetHeader
           name={'Terms & Condition'}
        />

        <section className="content_page text-left">
        <div className="container">
            <div className="row content_page_style justify-content-center">
             <div className="col-md-12">
               <h2 className="mb-4">Terms and Conditions</h2>
               <p><strong>DO NOT USE OnlineRoadService WHILE DRIVING OR OPERATING A VEHICLE.</strong></p>
               <p>Please carefully review these Terms and Conditions ("Terms") before accessing or using OnlineRoadService’s website, mobile site, or smartphone applications (the "Service"). The Service is operated by OnlineRoadService ("we," "us," "our," or "the company"). Your use of the Service is subject to your acceptance of and compliance with these Terms, which apply to all visitors, users, and others who access the Service.</p>
               <p>By accessing or using the Service, you agree to be bound by these Terms. If you disagree with any part of these Terms, you are not permitted to use the Service. We may modify these Terms at any time without prior notice, and your continued use of the Service constitutes your agreement to the updated Terms. Amendments become effective immediately upon posting.</p>

               <h3>Service Description</h3>
               <p>OnlineRoadService provides online advertising and marketing services for repair facilities, roadside assistance, towing, and trucking-related services across the United States. The Service includes access to an online business directory ("Advertisers") via OnlineRoadService’s website and applications. Users must provide necessary internet-enabled devices and bear the associated costs.</p>

               <h3>User Accounts</h3>
               <p>Users will receive a password and account upon registration and are fully responsible for activities under their account. Unauthorized use must be reported immediately. Accounts are personal and cannot be resold or used commercially without written consent from OnlineRoadService.</p>

               <h3>Vendor Information Disclaimer</h3>
               <p>Vendor-provided or publicly sourced product information is shared on the platform. While OnlineRoadService strives for accuracy, it does not guarantee the reliability of this information. Users assume all risks associated with relying on vendor representations.</p>

               <h4>Service Changes and Interruptions</h4>
               <p>OnlineRoadService may modify or discontinue the Service at any time without notice. We are not liable for interruptions or operational challenges beyond our control.</p>

               <h4>Privacy</h4>
               <p>Using the Service is subject to OnlineRoadService’s Privacy Policy. Third-party websites linked from our platform are governed by their respective terms and privacy policies. We are not responsible for their content or availability.</p>

               <h4>Prohibited Activities</h4>
               <p>Your use of OnlineRoadService Services must comply with these Terms. Unauthorized use, such as copying content, competing with OnlineRoadService, or engaging in fraudulent or harmful activities, is strictly prohibited. Violation may result in account termination and legal action.</p>

               <h4>User Information</h4>
               <p>By using the Service, you may provide personal information, which we may share with Advertisers to address your requests. By submitting information, you consent to being contacted by OnlineRoadService and its partners through various channels, including SMS and email. Users must provide accurate information and update it as necessary. Providing false or misleading information may result in penalties or legal action.</p>

               <h5>Intellectual Property</h5>
               <p>All content and software on the platform are owned by OnlineRoadService and are protected by intellectual property laws. Unauthorized use, modification, or distribution of this content is prohibited. Trademarks displayed on the platform belong to their respective owners.</p>

               <h5>Copyright Infringement Notification</h5>
               <p>Claims of copyright infringement can be reported to OnlineRoadService via:</p>
               <ul>
                  <li><strong>Phone:</strong> 307-776-0076</li>
                  <li><strong>Email:</strong> info@onlineroadservices.com</li>
               </ul>

               <h5>Legal Compliance</h5>
               <p>Users must comply with all applicable local, state, and federal laws while using the Service. Any actions encouraging unlawful behavior are prohibited.</p>

               <h5>Warranties Disclaimer</h5>
               <p>OnlineRoadService provides the Service "as is" without any warranties. This includes warranties related to Advertisers, accuracy, or reliability. OnlineRoadService is not liable for damages resulting from the use or inability to use the Service.</p>

               <h5>Limitation of Liability</h5>
               <p>OnlineRoadService is not responsible for direct, indirect, or incidental damages arising from the use of the platform. Certain jurisdictions may not permit this limitation, so it may not apply to all users.</p>

               <h6>Indemnification</h6>
               <p>Users agree to indemnify OnlineRoadService, its affiliates, and partners against claims arising from their use of the Service or violation of these Terms.</p>

               <h6>General Terms</h6>
               <p>These Terms are governed by Illinois law and disputes will be resolved in DuPage Country, Illinois. Any claims must be filed within one year of occurrence. If any provision is deemed unenforceable, the remaining Terms remain in effect.</p>

             </div>

          </div>
        </div>
       </section>
      </>
    );
};

export default TermsAndCondition;